var swiper__headerHome = new Swiper('.swiper__headerHome', {
    slidesPerView: 1,
    spaceBetween: 0,
    grabCursor: false,
    loop: true,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});